<template>
<span>
    <v-layout mb-4>
        <v-flex xs10 ml-3>
            <h2 class="lime--text lighten-1 pt-3">Gerenciar Imagens - {{ product.prod_nome }}</h2>
        </v-flex>
        <v-flex xs2 mr-3 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'products', params: {product_id: prod_id}}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <galeria-imagem-product v-on:atualiza="loadProductWithPhotos" :product="product"></galeria-imagem-product>

</span>
</template>

<script>
import GaleriaImagemProductComponent from "./partial/GaleriaImagemProductComponent";

export default {
    name: "AddImagemProductComponent",
    created() {
        this.loadProductWithPhotos()
    },
    props: {
        prod_id: {
            require: true
        }
    },
    data() {
        return {
            product: {},
            btnLabel: 'Salvar',
        }
    },
    methods: {
        loadProductWithPhotos() {
            this.$store.dispatch('loadProductWithPhotos', this.prod_id)
                .then(response => this.product = response)
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
        },
    },
    components: {
        GaleriaImagemProduct: GaleriaImagemProductComponent,
    }
};
</script>

<style scoped>

</style>
